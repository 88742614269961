
import {_getSession} from 'api/rpc/rpcBase';
function getHostName () {
    let h = location.hostname;
    return (/:/.test(h)) ? (/\[|\]/.test(h)) ? h : '[' + h + ']' : h;
}
function getPort () {
    if (location.port === '') {
        if (location.protocol === 'http:') {
            return 80;
        } else {
            return 443;
        }
    } else {
        return location.port;
    }
}
function stringToUint8Array (inputString) {
    let stringLength = inputString.length;
    let outputUint8Array = new Uint8Array(new ArrayBuffer(stringLength));
    for (let i = 0; i < stringLength; i++) {
        outputUint8Array[i] = inputString.charCodeAt(i);
    }
    return outputUint8Array;
}
function decodeUTF8 (buffer) {
    var array = [].slice.call(buffer);
    var str = '';
    for (var i = 0; i < array.length; i++) {
        str += String.fromCharCode(array[i]);
    }
    return decodeURIComponent(escape(str));
}
/**
 * 解析元数据信息帧
 * @param buffer 数据
 * return {result:true, type:0x00, params:{...}} result:解析是否成功
 */
function ParseAssist0x00 (buffer) {
    var info = {result:true, type:0x00};
    //var infoStr = new TextDecoder("utf-8").decode(buffer);
    //info.params = JSON.parse(infoStr);
    info.params = JSON.parse(decodeUTF8(buffer));
    return info;
}
let id = 1;
export default class WS {
    constructor (url) {
        let ip = getHostName(), port = getPort(), wsProtocal = location.protocol === 'http:' ? 'ws' : 'wss';
        if (process.env.NODE_ENV === 'development') { // 开发模式
            // let proxy = require('../../proxy');
            let proxy = {
                ip: '172.29.2.127', //AI推图
                port: 80
            };
            ip = proxy.ip;
            port = proxy.port;
            // wsProtocal = proxy.protocol === 'http' ? 'ws' : 'wss';
        }
        let wsUrl = `${wsProtocal}://${ip}:${port}${url}`;
        this.ws = new WebSocket(wsUrl);
        this.ws.binaryType = 'arraybuffer';
        this.sendCallback = {}; // 根据ID对应请求
        this.topics = {}; // 消息订阅
        this.ws.onmessage = (res) => {
            let recvMessage = new Uint8Array(res.data);
            let commDataSize = (recvMessage[1] << 8) + recvMessage[0];
            let CommData = recvMessage.subarray(2, commDataSize + 2);
            CommData = ParseAssist0x00(CommData).params;
            let recvData = recvMessage.subarray(2 + commDataSize);

            if (CommData.Type === 'Response') { // 表示设备请求返回
            //    console.log(recvData);
                recvData = ParseAssist0x00(recvData).params; // 返回的json配置数据
                let reqId = recvData.id;
                this.sendCallback[reqId] && this.sendCallback[reqId](recvData);
                delete this.sendCallback[reqId];
            } else if (CommData.Type === 'Notification') { // 表示设备推送
                if (CommData.BinSize) { // 表示有二进制数据
                    let jsonSize = CommData.TotalSize - CommData.BinSize;
                    let arr = [];
                    for (let i = 0; i < recvData.length; i++) {
                        arr.push(recvData[i].toString(16));
                    }
                    //  let recvJson = recvData.subarray(0, jsonSize - 1);
                    let recvJson = recvData.subarray(0, jsonSize);
                    recvJson = ParseAssist0x00(recvJson).params;
                    if (recvJson.params) { // 将二进制数据存放在buffer中
                        //  recvJson.params.buffer = recvData.subarray(jsonSize - 1, jsonSize - 1 + CommData.BinSize);
                        recvJson.params.buffer = recvData.subarray(jsonSize, jsonSize + CommData.BinSize);
                    }
                    recvData = recvJson;
                } else {
                    recvData = ParseAssist0x00(recvData).params; // 返回的json配置数据
                }
                this.publish(recvData);
            } else {
                recvData = ParseAssist0x00(recvData).params; // 返回的json配置数据
            }
        // console.log(CommData, recvData);
        };
        this.ws.onclose = function (e) {
            // console.log('websocket 断开: ' + e.code + ' ' + e.reason + ' ' + e.wasClean);
        };
    }
    _send (method, data, callback, exObj = {}, url = 'RPC2') {
        let session = _getSession();
        this.sendCallback[id] = callback;
        let json = {
            'method': method,
            'params': data,
            'id': id++,
            'session': session
        };
        Object.assign(json, exObj);

        let type = 'Request';
        if (url === 'SubscribeNotify') {
            type = 'SubScribe';
        }
        json = JSON.stringify(json);
        let CommData = {
            TotalSize: json.length,
            Type: type,
            SessionID: session,
            URL: url
        };
        CommData = JSON.stringify(CommData);
        let size = 2 + CommData.length + json.length; // 总长度
        let sendArray = new Uint8Array(size);
        let sizeArr = [];

        sizeArr[0] = CommData.length & 0xFF;
        sizeArr[1] = (CommData.length >> 8) & 0xFF;
        sendArray.set(sizeArr, 0);
        sendArray.set(stringToUint8Array(CommData), 2);
        sendArray.set(stringToUint8Array(json), 2 + CommData.length);
        this.ws.send(sendArray);
    }

    send (method, data, exObj = {}, url = 'RPC2') { // 封装为Promise
        return new Promise((resolve) => {
            this._send(method, data, (res) => {
                resolve(res);
            }, exObj, url);
        });
    }

    subscribe (topic, listener) {
        this.topics[topic] = listener;
    }

    publish (info) {
        // console.log(info);
        let topic = info.method;
        if (!this.topics[topic]) return;
        try {
            this.topics[topic](info.params);
        } catch(e) {
            // console.log(e);
        }
    }

    detach (topic) {
        delete this.topics[topic];
    }
    /**
     * 关闭websocket连接
     */
    closeWS () {
        this.ws.close();
    }
}
